import React from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { FaFacebook, FaLinkedin, FaTwitter, FaGoogle } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import PageTemplate from "./page";
import SectionLayout5 from "../components/section-layout-5";
import uuid4 from "uuid4";

const BlogPostTemplate = ({ data }) => {
	const { post, site, defaultImage, defaultBanner } = data;
	const siteUrl = site.siteMetadata.siteUrl;
	const postUrl = `${site.siteMetadata.siteUrl}/${post.slug}`;

	const newHeading =
		post.blogCtaFields.ctaBanner.heading ||
		defaultBanner.blogCtaBannerFields.ctaBanner.heading;

	const newDescription =
		post.blogCtaFields.ctaBanner.description ||
		defaultBanner.blogCtaBannerFields.ctaBanner.description;

	const newLink =
		post.blogCtaFields.ctaBanner.link ||
		defaultBanner.blogCtaBannerFields.ctaBanner.link;
	const newImage =
		post.blogCtaFields.ctaBanner.image ||
		defaultBanner.blogCtaBannerFields.ctaBanner.image;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/${post.slug}`,
					id: `${siteUrl}/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post?.seoFieldGroups?.metaTitle}
				description={post?.seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/${post.slug}`,
					title: `${post?.seoFieldGroups?.openGraphTitle}`,
					description: `${post?.seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${post?.seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${post?.seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${post?.seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${post?.seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@amberthdesign",
					site: "@amberthdesign",
					cardType: "summary",
				}}
			/>

			<section className="py-5 py-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={7}>
							{post.postFields.featureImage && (
								<GatsbyImage
									image={
										post.postFields.featureImage.localFile.childImageSharp
											.gatsbyImageData
									}
									className="w-100 blog-feature-image"
								/>
							)}

							<h1 className="my-4 text-light-black">{post.title}</h1>
							<div className="blog-content">
								<SafeHtmlParser htmlContent={post.content} />
							</div>
							<div className="share-wrapper text-center mt-7">
								<h6 className="mb-3 text-secondary text-sm daxPro-semibold">
									Share this article:
								</h6>
								<div class="sco-icon d-flex justify-content-center mb-4 pb-2">
									<a
										class="icon-social d-flex align-items-center justify-content-center"
										href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${postUrl}`}
									>
										<FaLinkedin />
									</a>
									<a
										class="icon-social d-flex align-items-center justify-content-center"
										href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`}
									>
										<FaFacebook />
									</a>
									<a
										class="icon-social d-flex align-items-center justify-content-center"
										href={`https://twitter.com/home?status=${postUrl}`}
									>
										<FaTwitter />
									</a>
									<a
										class="icon-social d-flex align-items-center justify-content-center"
										href={`https://plus.google.com/share?url=${postUrl}`}
									>
										<FaGoogle />
									</a>
									<a
										class="icon-social d-flex align-items-center justify-content-center"
										href={`mailto:enquiries@amberth.com?subject=${postUrl}/&amp;body=`}
									>
										<AiOutlineMail />
									</a>{" "}
								</div>
								<h3 className="text-secndary daxPro-regular">{post.title}</h3>
							</div>

							<SectionLayout5
								heading={newHeading}
								content={newDescription}
								button={newLink}
								image={newImage?.localFile.childImageSharp.gatsbyImageData}
								imageAlt={newImage?.altText}
								key={uuid4()}
							/>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		defaultBanner: wpComponent(id: { eq: "cG9zdDoyMjE0Mg==" }) {
			id
			blogCtaBannerFields {
				ctaBanner {
					heading
					description
					image {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					link {
						target
						title
						url
					}
				}
			}
		}
		post: wpPost(id: { eq: $id }) {
			blogCtaFields {
				ctaBanner {
					heading
					description
					image {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					link {
						target
						title
						url
					}
				}
			}
			seoFieldGroups {
				metaTitle
				metaDescription
				localBusinessSchema
				openGraphDescription
				openGraphTitle
				productSchema
				image {
					altText
					publicUrl
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
			id
			excerpt
			slug
			content
			author {
				node {
					name
				}
			}
			postFields {
				featureImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
			title
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
		defaultImage: wpMediaItem(
			title: { eq: "5c5ee5fbe48f547ac31c0ed84f5365d8" }
		) {
			id
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
	}
`;
